// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-nase-aktivity-index-tsx": () => import("./../../../src/pages/nase-aktivity/index.tsx" /* webpackChunkName: "component---src-pages-nase-aktivity-index-tsx" */),
  "component---src-pages-reference-index-tsx": () => import("./../../../src/pages/reference/index.tsx" /* webpackChunkName: "component---src-pages-reference-index-tsx" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-webscan-index-tsx": () => import("./../../../src/pages/webscan/index.tsx" /* webpackChunkName: "component---src-pages-webscan-index-tsx" */),
  "component---src-templates-about-page-en-js": () => import("./../../../src/templates/about-page-en.js" /* webpackChunkName: "component---src-templates-about-page-en-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-coop-page-en-js": () => import("./../../../src/templates/coop-page-en.js" /* webpackChunkName: "component---src-templates-coop-page-en-js" */),
  "component---src-templates-index-page-en-js": () => import("./../../../src/templates/index-page-en.js" /* webpackChunkName: "component---src-templates-index-page-en-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-redirect-page-js": () => import("./../../../src/templates/redirect-page.js" /* webpackChunkName: "component---src-templates-redirect-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

